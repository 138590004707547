import { render, staticRenderFns } from "./bridge.vue?vue&type=template&id=21c63901&scoped=true&"
import script from "./bridge.vue?vue&type=script&lang=js&"
export * from "./bridge.vue?vue&type=script&lang=js&"
import style0 from "./bridge.vue?vue&type=style&index=0&lang=css&"
import style1 from "./bridge.vue?vue&type=style&index=1&id=21c63901&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21c63901",
  null
  
)

export default component.exports